<template>
    <v-container class="my-10 my-md-16">
        <v-row class="d-flex flex-row pa-3 heading">
            <v-col>
                <h1 class="text-left">{{ $t("navbar.statistics") }}</h1>
                <p class="text-left">
                    {{ $t("navbar.statisticsDesc") }}
                </p>
            </v-col>
            <v-col>
                <v-autocomplete v-model="selectedSite" :items="siteNames" :label="$t('Statistics.label')"
                    @change="fetchSiteStatistics" color="#9BA9BD" class="mb-4 mt-6"></v-autocomplete>
            </v-col>
        </v-row>

        <v-row class="d-block d-md-flex" v-if="statistics">
            <v-col class="col-12 col-md-4">
                <div
                    class="profile d-flex text-left justify-space-around justify-md-center align-center px-5 py-6 rounded-xl fill-height">
                    <div class="d-flex flex-column justify-between">
                        <h4 class="wrap text-capitalize">{{ selectedSite }}</h4>
                        <p class="wrap">{{ this.statistics.description }}</p>
                    </div>
                    <img :src="statistics.logo_path ? statistics.logo_path : require('../assets/illustrations/no-photo.jpg')"
                        alt="" width="100" height="100" class="rounded-pill" />
                </div>
            </v-col>
            <v-col class="col-12 col-md-8">
                <div class="profile d-flex text-left justify-center align-center  rounded-xl fill-height py-2 px-2">
                    <div class="tooltip-container">
                        <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            class="info-icon"
                            v-bind="attrs"
                            v-on="on"
                            size="24"
                            >
                            mdi-information-outline
                            </v-icon>
                        </template>

                        <div>
                            <h4>Plataformas que pagan:</h4>
                            <p>
                                Chaturbate 15%, Streamate 30%, MyfreeCams 20%, Livejasmin 65%, <br>Stripchat 65%, Cherrytv 20%, Cam4 80%, Camsoda 65%, <br>Imlive 65%, Xlovecam 65%, Cams 65%, F4f 14%, Bongacams 65%.
                            </p>

                            <h4>Pronto:</h4>

                            <p>Skyprivate, Amateur.tv, Onlyfans, Manyvids, <br>Loyalfans, Pornhub, DreamCam, Friends2Follow, <br>Streamfans.</p>

                        </div>

                        </v-tooltip>
                    </div>
                    <v-row class="fill-height  justify-space-around justify-md-space-around px-3 py-6">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-container class="m-w mt-0  pa-md-0">
                                        <h4 class="number">{{ formatNumber(statistics.visits) }}</h4>
                                        <p>{{ $t('Statistics.totalViews') }}</p>
                                        <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="rgb(151, 138, 206)"
                                        class="mt-4"
                                        />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0" size="40">mdi-eye-check</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-totalViews') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-0 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-container class="m-w mt-0 pa-md-0">
                                        <h4 class="number">{{ formatNumber(statistics.uniques) }}</h4>
                                        <p>
                                            {{ $t('Statistics.uniqueViews') }}
                                            <v-icon size="16">
                                                mdi-cash
                                            </v-icon>
                                        </p>
                                        <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="rgb(151, 138, 206)"
                                        class="mt-4"
                                        />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0" size="40">mdi-account-eye</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-UniqueViews') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-container class="m-w mt-0  pa-md-0">
                                        <h4 class="number">{{ formatNumber(statistics.clicks) }}</h4>
                                        <p>{{ $t('Statistics.totalClicks') }}</p>
                                        <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="rgb(151, 138, 206)"
                                        class="mt-4"
                                        />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0" size="40">mdi-cursor-default-click</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-TotalClicks') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-container class="m-w mt-0  pa-md-0 m-auto">
                                        <h4 class="number">{{ formatNumber(statistics.unique_clicks) }}</h4>
                                        <p>{{ $t('Statistics.totalClicksUniques') }}</p>
                                        <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="rgb(151, 138, 206)"
                                        class="mt-4"
                                        />
                                    </v-container>
                                    <v-icon v-if="!loading" class="icon pa-3 pa-md-0" size="40">mdi-cursor-default-click</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-TotalUniqueClicks') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-col
                                    class="col-12 col-sm-5 col-md-2 px-4 d-flex flex-column justify-space-between pb-6 pt-0"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-container class="m-w mt-0  pa-md-0 m-auto">
                                        <h4 class="number">${{ getAmount(statistics.uniques) }}</h4>
                                        <p>{{ $t('Statistics.totalEarnings') }}</p>
                                        <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="rgb(151, 138, 206)"
                                        class="mt-4"
                                        />
                                    </v-container>
                                    <v-icon v-if="!loading" size="40" class="icon pa-3  pa-md-0">mdi-cash-multiple</v-icon>
                                </v-col>
                            </template>
                            <span>{{ $t('Statistics.tooltip-TotalEarnings') }}</span>
                        </v-tooltip>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <v-col class="chart rounded-xl mt-6">
            <v-row class="d-flex justify-content-right">
                <v-col class="col-md-3 col-sm-5 col-8 ml-auto mr-0 pt-8 pr-16">
                    <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="selectedMonth" :label="$t('Statistics.select_month')"
                                prepend-icon="mdi-calendar-month" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker elevation="24" v-model="selectedMonth" @input="onDateSelected" type="month"
                            :first-day-of-week="1" :locale="this.lang" color="#8e84c0"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <bar-chart class=" mx-auto mt-2 px-sm-12 px-5 pb-12 rounded-xl" :chart-data="chartData"></bar-chart>
        </v-col>

        <v-row no-gutters>
            <v-col class="chart rounded-xl mt-6">
            <horizontal-bar-chart class=" mx-auto mt-2 px-sm-12 px-5 pb-12 rounded-xl" :horizontal-data="horizontalBarChartData"></horizontal-bar-chart>
        </v-col>
        </v-row>


        
       <v-row no-gutters>
        <v-col class="chart rounded-xl mt-6">
            <bar-chart-percent class="mx-auto mt-2 px-sm-12 px-5 pb-12 rounded-xl"    :chart-data="chartPercent"></bar-chart-percent>
        </v-col>
       </v-row>

        <v-row class="pt-2">
            <v-col class="col-12 col-sm-6 col-md-3" v-if="Object.keys(deviceStats).length">
                <div class="pa-5 mt-4 pb-0 chart rounded-xl text-left">
                    <h4>{{ $t('Statistics.mostUsedDevices') }}</h4>
                    <doughnut-chart style="max-height:250px ; margin:auto;"
                        :doughnut-data="deviceStatsData"></doughnut-chart>
                    <div>
                        <v-icon class="arrow" @click="deviceExpand = !deviceExpand"
                            :class="{ 'actived-arrow': deviceExpand }">mdi-play</v-icon>
                        <span class="show">{{ deviceExpand ? $t('Statistics.hide') : $t('Statistics.show_more') }}</span>
                        <v-expand-transition>
                            <v-card v-show="deviceExpand" class="mx-auto chart" height="100" width="100">
                                <ul class="list">
                                    <li v-for="(count, device, index) in deviceStats" :key="device">
                                        <span :style="{ backgroundColor: deviceColors[index] }" class="color-box"></span>
                                        {{ device }}: {{ count }}
                                    </li>
                                </ul>
                            </v-card>
                        </v-expand-transition>
                    </div>
                </div>
            </v-col>

            <v-col class="col-12 col-sm-6 col-md-3" v-if="Object.keys(countryStats).length">
                <div class="pa-5 mt-4 pb-0 chart rounded-xl text-left ">
                    <h4>{{ $t('Statistics.topCountries') }}</h4>
                    <v-col class="col-12 px-0  mt-md-0 mt-0 pt-0 pb-2">
                        <v-card cols="12" class="rounded-lg  py-4" elevation="2">
                            <!-- Contenedor para las etiquetas -->
                            <div class="chip-container px-1 py-3">
                                <v-chip
                                    v-for="(country, index) in countryData"
                                    :key="index"
                                    class="ma-1 white--text"
                                    color="#cdafe9"
                                >
                                    <img :src="country.flag" class="flags rounded-pill">&nbsp; {{ country.name }}: {{ formatNumber(country.total) }}
                                </v-chip>
                            </div>
                        </v-card>
                    </v-col>
                </div>
            </v-col>

            <v-col class="col-12 col-sm-6 col-md-3" v-if="Object.keys(regionStats).length">
                <div class="pa-5 mt-4 pb-0 chart rounded-xl text-left ">
                    <h4>{{ $t('Statistics.topRegions') }}</h4>
                    <v-col class="col-12 px-0 pb-2 mt-0 pt-0">
                        <v-card cols="12" class="rounded-lg  py-4" elevation="2">
                            <!-- Contenedor para las etiquetas de regiones -->
                            <div class="chip-container px-1 py-3">
                                <v-chip
                                    v-for="(region, index) in regionData"
                                    :key="index"
                                    class="ma-1 white--text"
                                    color="#cdafe9"
                                >
                                    {{ region.name }}: {{ formatNumber(region.total) }}
                                </v-chip>
                            </div>
                        </v-card>
                    </v-col>
                </div>
            </v-col>




            <v-col class="col-12 col-sm-6 col-md-3" v-if="Object.keys(regionStats).length">
                <div class="pa-5 mt-4 pb-0 chart rounded-xl text-left ">
                    <h4>{{ $t('Statistics.topCities') }}</h4>
                    <v-col class="col-12 px-0 pb-2 mt-0 pt-0">
                        <v-card cols="12" class="rounded-lg  py-4" elevation="2">
                            <!-- Contenedor para las etiquetas de regiones -->
                            <div class="chip-container px-1 py-3">
                                <v-chip
                                    v-for="(region, index) in cityData"
                                    :key="index"
                                    class="ma-1 white--text"
                                    color="#cdafe9"
                                >
                                    {{ region.name }}: {{ formatNumber(region.total) }}
                                </v-chip>
                            </div>
                        </v-card>
                    </v-col>
                </div>
            </v-col>

        </v-row>

    </v-container>

</template>


<script>
import axios from "@/axios.js";
import { Bar, Doughnut,HorizontalBar } from "vue-chartjs";
import "chartjs-plugin-colorschemes";
import moment from "moment";
import  { getCountryFlagUrl } from "@/utils/countries.js";

export default {
    watch: {
        "$i18n.locale": function () {
            this.prepareChartData();
            this.prepareDoughnutChartData();
            this.checkLanguage()
            this.prepareChartHorizontal()
            this.prepareChartPercent()
        },
    },
    name: "Statistics",
    components: {
        "bar-chart": {
            extends: Bar,
            props: ["chartData"],
            mounted() {
                this.renderChart(this.chartData, {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                precision: 0,
                            },
                        },
                    },
                });
            },
            watch: {
                chartData(newData) {
                    this.renderChart(newData, {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    precision: 0,
                                },
                            },
                        },
                    });
                },
            },
        },
        "horizontal-bar-chart": {
            extends: HorizontalBar,
            props: ["horizontalData"],
            mounted() {
                this.renderChart(this.horizontalBarChartData, {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                            }
                        }]
                    },
                    title: {
                        display: true,
                        text: this.$t('Statistics.title-links')
                    }
                 });
             },
             watch: {
                 horizontalData(newData) {
                     this.renderChart(newData, {
                         responsive: true,
                         maintainAspectRatio: false,
                         scales: {
                             xAxes: [{
                                 ticks: {
                                     beginAtZero: true,
                                     precision: 0,
                                 }
                             }]
                         },
                         title: {
                             display: true,
                             text:  this.$t('Statistics.title-links')
                         }
                     });
                 },
             },
        },
        "doughnut-chart": {
            extends: Doughnut,
            props: ["doughnutData"],
            mounted() {
                this.renderChart(this.doughnutData, {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                        position: 'bottom',
                        labels: {
                            padding: 0,
                            boxWidth: 10,
                        },
                    },

                });
            },
            watch: {
                doughnutData(newData) {
                    this.renderChart(newData, {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                            position: 'bottom',
                            labels: {
                                padding: 20,
                                boxWidth: 10,
                            },
                        },
                    });
                },
            },
        },

        "bar-chart-percent": {
            extends: Bar,
            props: ["chartData"],
            mounted() {
                this.renderChart(this.chartData, {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            max: 100,
                            callback: function(value) {
                                return value + "%";
                            },
                        },
                    }],
                    },
                    title: {
                        display: true,
                        text:'Tasa de Clics entre Visitantes Únicos'
                    }
                });
            },
            watch: {
                chartData(newData) {
                    this.renderChart(newData, {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            max: 100,
                            callback: function(value) {
                                return value + "%";
                            },
                        },
                    }],
                        },
                        title: {
                        display: true,
                        text:'Tasa de Clics entre Visitantes Únicos'
                        }
                    });
                },
            },
        }
    },
    data() {
        return {
            sites: [],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#E57373", "#F06292", "#BA68C8", "#64B5F6", "#4FC3F7", "#81C784", "#FFD54F"],
            selectedSite: null,
            statistics: null,
            chartData: null,
            chartPercent: null,
            deviceStatsData: null,
            deviceColors: null,
            deviceExpand: false,
            loading: false,
            selectedMonth: this.formatToYearMonth(new Date()),
            menu: false,
            lang: "es",
            horizontalBarChartData: null,
            chartOptionsPercent: {
                scales: {
                    y: {
                        beginAtZero: true,
                        max: 100,
                        ticks: {
                            callback:(value) => {
                                return value + "%";
                            },
                        },
                    },
                },
            },
        };
    },

    created() {
        this.$i18n.locale = 'es';
    },

    computed: {

        siteNames() {
            return this.sites.map((site) => site.name);
        },
        deviceStats() {
            if (!this.statistics) return {};
            const deviceCounts = {};
            this.statistics.details.forEach((detail) => {
                const device = this.extractDevice(detail.user_agent);
                if (!deviceCounts[device]) {
                    deviceCounts[device] = 0;
                }
                deviceCounts[device]++;
            });
            return deviceCounts;
        },
        countryStats() {
            if (!this.statistics) return {};
            const countryCounts = {};
            this.statistics.details.forEach((detail) => {
                const country = detail.country || "Unknown";
                if (!countryCounts[country]) {
                    countryCounts[country] = 0;
                }
                countryCounts[country]++;
            });
            return countryCounts;
        },
        regionStats() {
            if (!this.statistics) return {};
            const regionCounts = {};
            this.statistics.details.forEach((detail) => {
                const region = detail.region || "Unknown";
                if (!regionCounts[region]) {
                    regionCounts[region] = 0;
                }
                regionCounts[region]++;
            });
            return regionCounts;
        },
        cityStats() {
            if (!this.statistics) return {};
            const cityCounts = {};
            this.statistics.details.forEach((detail) => {
                const city = detail.city || "Unknown";
                if (!cityCounts[city]) {
                    cityCounts[city] = 0;
                }
                cityCounts[city]++;
            });

            return cityCounts;
        },
    },

    methods: {

        getAmount(value){
            let amount = value * 0.01
            return amount.toFixed(2)
        },

        generateRandomColors(count) {
        const baseColors = [
            "#FF6384", "#36A2EB", "#FFCE56", "#E57373", "#F06292", 
            "#BA68C8", "#64B5F6", "#4FC3F7", "#81C784", "#FFD54F"
        ];
        
        const colors = [];
        for (let i = 0; i < count; i++) {
            if (i < baseColors.length) {
            colors.push(baseColors[i]);
            } else {
            // Si necesitamos más colores, generamos uno aleatorio
            const r = Math.floor(Math.random() * 256);
            const g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);
            colors.push(`rgba(${r}, ${g}, ${b}, 0.6)`);
            }
        }
        return colors;
        },

        onDateSelected(date) {
            this.selectedMonth = date;
            this.fetchSites();
        },

        checkLanguage() {
            const storedLocale = localStorage.getItem("locale");
            this.lang = storedLocale;
        },

        async fetchSites() {
            this.loading = true
            try {
                const response = await axios.get("site-statistics", {
                    params: {
                        month: this.selectedMonth
                    }
                });
                this.sites = response.data;

                if (this.sites.length > 0) {
                    if (this.selectedSite == null) {
                        this.selectedSite = this.sites[0].name;
                    }
                    this.fetchSiteStatistics();
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                console.error("Error fetching sites:", error);
            }
        },
        async fetchSiteStatistics() {
            const site = this.sites.find((s) => s.name === this.selectedSite);
            if (site) {
                this.statistics = site;
                this.prepareChartData();
                this.prepareDoughnutChartData();
                this.prepareChartHorizontal()
                this.prepareChartPercent()
            }
        },
        prepareDoughnutChartData() {
            if (!this.statistics) return {};
            const cityCounts = {};
            const deviceCounts = {};
            const countryCounts = {};
            const regionCounts = {};

            this.statistics.details.forEach((detail) => {

                const city = detail.city || "Unknown";
                const country = detail.country || "Unknown";
                const region = detail.region || "Unknown";
                const device = this.extractDevice(detail.user_agent);

                if (!cityCounts[city]) {
                    cityCounts[city] = 0;
                }
                cityCounts[city]++;

                if (!deviceCounts[device]) {
                    deviceCounts[device] = 0;
                }
                deviceCounts[device]++;

                if (!countryCounts[country]) {
                    countryCounts[country] = 0;
                }
                countryCounts[country]++;

                if (!regionCounts[region]) {
                    regionCounts[region] = 0;
                }
                regionCounts[region]++;
            });

            const labelsDevice = Object.keys(deviceCounts);
            const dataDevice = Object.values(deviceCounts);

            this.countryData = Object.keys(countryCounts)
                .map(country => ({
                    name: country,
                    total: countryCounts[country],
                    flag: getCountryFlagUrl(country)
                }))
                .sort((a, b) => b.total - a.total);

            this.cityData = Object.keys(cityCounts).map(city => ({
                name: city,
                total: cityCounts[city]
            }))
                .sort((a, b) => b.total - a.total);

            this.regionData = Object.keys(regionCounts).map(region => ({
                name: region,
                total: regionCounts[region]
            }))
                .sort((a, b) => b.total - a.total);

            this.deviceColors = this.generateRandomColors(labelsDevice.length);

            this.deviceStatsData = {
                labels: labelsDevice,
                datasets: [
                    {
                        backgroundColor:  this.deviceColors,
                        data: dataDevice,
                        borderWidth: 0.5,
                    },
                ],
            };
        },
        extractDevice(userAgent) {
            const deviceRegex = /\(([^)]+)\)/;
            const match = userAgent.match(deviceRegex);
            return match ? match[1].split(";")[0] : userAgent;
        },
        prepareChartData() {
            const details = this.statistics.details;
            const groupedDetails = details.reduce((acc, detail) => {
                const date = moment(detail.time).format("YYYY-MM-DD");
                if (!acc[date]) {
                    acc[date] = {
                        views: 0,
                        uniqueViews: 0,
                        devices: new Set(),
                        countries: new Set(),
                        regions: new Set(),
                        cities: new Set(),
                    };
                }
                acc[date].views += 1;
                acc[date].uniqueViews += detail.is_unique ? 1 : 0;

                    const device = this.extractDevice(detail.user_agent);
                    acc[date].devices.add(device);

                    const country = detail.country || "Unknown";
                    acc[date].countries.add(country);

                    const region = detail.region || "Unknown";
                    acc[date].regions.add(region);

                    const city = detail.city || "Unknown";
                    acc[date].cities.add(city);

                    return acc;
                }, {});



            const labels = Object.keys(groupedDetails);
            const views = labels.map((date) => groupedDetails[date].views);
            const uniqueViews = labels.map(
                (date) => groupedDetails[date].uniqueViews
            );

            const deviceViews = labels.map((date) => groupedDetails[date].devices.size);
            const countryViews = labels.map((date) => groupedDetails[date].countries.size);
            const regionViews = labels.map((date) => groupedDetails[date].regions.size);
            const cityViews = labels.map((date) => groupedDetails[date].cities.size);

            this.chartData = {
                labels,
                datasets: [
                    {
                        label: this.$t("Statistics.chart-views"),
                        backgroundColor: "rgba(66, 165, 245, 0.2)",
                        borderColor: "rgba(66, 165, 245, 1)",
                        borderWidth: 1, 
                        data: views,
                        type: "bar",
                    },
                    {
                        label: this.$t("Statistics.chart-uniqueViews"),
                        backgroundColor: "rgba(	102, 187, 106, 0.2)",
                        borderColor: "rgba(	102, 187, 106, 1)",
                        borderWidth: 1, 
                        data: uniqueViews,
                        type: "bar",
                    },
                    {
                        label: this.$t("Statistics.chart-Devices"),
                        fill: false,
                        data: deviceViews,
                        type: "line",
                        borderColor: "#ff6b6d",
                    },
                    {
                        label: this.$t("Statistics.chart-Countries"),
                        data: countryViews,
                        type: "line",
                        fill: false,
                    },
                    {
                        label: this.$t("Statistics.chart-Regions"),
                        data: regionViews,
                        type: "line",
                        fill: false,
                    },
                    {
                        label: this.$t("Statistics.chart-Cities"),
                        data: cityViews,
                        type: "line",
                        borderColor: "#978ace",
                        backgroundColor: (context) => {
                        const bgColor = [
                            "rgba(151, 138, 206, 0.6)",
                            "rgba(151, 138, 206, 0.4)",
                            "rgba(151, 138, 206, 0.2)",
                        ];
                        if (!context.chart.chartArea) {
                            return;
                        }
                        const {
                            ctx,
                            chartArea: { top, bottom },
                        } = context.chart;
                        const gradientBg = ctx.createLinearGradient(
                            0,
                            top,
                            0,
                            bottom
                        );
                        gradientBg.addColorStop(0, bgColor[0]);
                        gradientBg.addColorStop(0.5, bgColor[1]);
                        gradientBg.addColorStop(1, bgColor[2]);
                        return gradientBg;
                        },
                        fill: true,
                    },
                ],
            };
        },

        prepareChartPercent(){
            const percent = this.statistics.percent_unique;

            const labels = percent.map(item => item.date);
            const data = percent.map(item => item.percent);

            this.chartPercent = {
                labels: labels,
                datasets: [{
                    label: '%',
                    data: data,
                    backgroundColor: 'rgba(151, 138, 206, 0.2)',
                    borderColor: 'rgba(151, 138, 206, 1)',
                    borderWidth: 1, 
                    fill: false,
                }],
            };

        },
          prepareChartHorizontal() {
            const urlOrPlatforms = this.statistics.url_or_platforms;
        
            const labels = urlOrPlatforms.map(item => item.name);
            const data = urlOrPlatforms.map(item => item.count);
        
            const predefinedColors = [
                'rgba(151, 138, 206, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 206, 86, 0.2)'
            ];
        
            const backgroundColors = urlOrPlatforms.map((_, index) => predefinedColors[index % predefinedColors.length]);
            const borderColors = backgroundColors.map(color => color.replace('0.2', '1'));
        
            this.horizontalBarChartData = {
                labels: labels,
                datasets: [{
                    label: 'Clics',
                    data: data,
                    backgroundColor: backgroundColors,
                    borderColor: borderColors, 
                    borderWidth: 1, 
                    fill: false,
                }],

                options: {
                scales: {
                    xAxes: [{
                        ticks: {
                            precision: 0,
                        },
                    }],
                },
            },
            };
        },
        getColor(index) {
            return this.backgroundColor[index % this.backgroundColor.length];
        },
        formatNumber(value) {
            if (value >= 1000000) {
                return (value / 1000000).toFixed(1) + 'M';
            } else if (value >= 1000) {
                return (value / 1000).toFixed(1) + 'k';
            } else {
                return value;
            }
        },
        formatToYearMonth(date) {
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

        },
    },
    mounted() {
        this.fetchSites();
    },
};
</script>

<style scoped>
.v-container {
    max-width: 1200px;
    margin: 0 auto;
}
.tooltip-container {
    position: absolute;
    top: 10px;
    right: 13px;
}

.m-w {
    width: 100%;
    margin: 0;
    text-align: center
}

.heading h1 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.heading p {
    font-size: 0.8rem;
    color: rgb(151, 138, 206);
    font-weight: 600;
}

.profile,
.chart {
    gap: 20px;
    color: rgb(70, 70, 70);
    -webkit-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
}

.profile p {
    font-size: 0.7rem;
    max-width: 250px;
    margin: auto;
}

.profile img {
    font-size: 0.8rem;
    object-fit: cover;
}
.profile {
    position: relative !important;
}

.number {
    font-size: 1.8rem;
    line-height: 1.2;

}

.icon {
    color: rgb(151, 138, 206);
}

.list {
    list-style: none;
    padding: 0;
    font-size: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.chartjs-render-monitor {
    max-height: 350px !important;
}

.color-box {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    border-radius: 2px;
    margin-bottom: 2px;
}

.chip-container {
    display: flex;
    flex-wrap: wrap;  /* Permite que se dividan en múltiples filas */
    max-height: 240px; /* Ajusta esta altura si es necesario */
    overflow-x: auto;  /* Scroll horizontal */
    background-color: #f4e8ff;
    border-radius: 12px;
}

.flags {
    width: 21px;
}

.arrow {
    transform: rotate(90deg);
    padding: 0 !important;
    cursor: pointer;
    margin-bottom: 5px;
    border-radius: 50%;
    background-color: #c9b2ff;
    color: #8a72c1;
}

.actived-arrow {
    background-color: rgb(223, 223, 223);
    color: rgb(170, 170, 170);
}

.v-card {
    box-shadow: none !important;
    width: 100% !important;
    overflow: auto;
}

.show {
    margin-left: 5px;
    font-size: 0.7rem;
}

.wrap {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu {
    width: 200px !important;
}
</style>